body {
    background-color : #f2f2f2 !important;
}

.amplify-button--primary {
    background-color : #000;
    font-weight : 400;
}
.amplify-button--primary:hover {
    background-color : #000;
    opacity : 0.7;
}

input.amplify-input {
    background-color : #fff;
}

div[data-amplify-authenticator] > div[data-amplify-container] {
    display : flex;
    flex-direction : column;
    align-items : center;
    width : 100%;
}

div[data-amplify-container] > div[data-amplify-router] {
    display : flex;
    flex-direction : column;
    align-items : center;
    width : 100%;
    border : 0;
    box-shadow : none;
    background-color : #fafafa;
    padding-top : 30px;
}

div[data-amplify-container] > div[data-amplify-router] > div[data-amplify-router-content] {
    width : 100%;
}

div[data-amplify-router-content] > div {
    display : flex;
    flex-direction : column;
    align-items : center;
} 

div[data-amplify-router-content] form[data-amplify-form], 
div[data-amplify-router] form[data-amplify-form] {
    width : 460px;
    padding-top : 0;
    padding-left : 8px;
    padding-right : 8px;
    padding-bottom : 24px;
}

@media (max-width : 30rem) {
    div[data-amplify-router] form[data-amplify-form] {
        width : 350px;
    }
}

.amplify-button--link {
    color : #428bf9;
}
.amplify-button--link:hover {
    background-color : #fff;
    color : #428bf9;
    text-decoration : underline;
}
